import { createGlobalStyle ,css} from "styled-components";

export const fontFaces = css`
    @import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Kanit:wght@300&family=Moirai+One&family=Monoton&display=swap');
`;

const GlobleStyle = createGlobalStyle`
    ${fontFaces};
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* font-family: 'Montserrat', sans-serif; */
    }

    html, body{
        overflow-x: hidden;
        scroll-behavior: smooth;
        background: #D0CFBB;
    }
    body::-webkit-scrollbar {
        width: 5px;
        background: none;
        display: none;
    }
    
    body::-webkit-scrollbar-track {
        
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #D4AF37;
        outline: 1px solid slategrey;
    }
`;

export default GlobleStyle;